import get from 'lodash.get'
import {
  BasicOperator,
  Predicate,
  GroupPredicate,
  SinglePredicate,
  FieldType,
  Operator,
  GroupOperator
} from './types'
import { evaluate as mathEvaluate } from 'mathjs'
export * from './types'

export const customFieldKey = 'custom'

export function evaluate<Context>(
  predicate: Predicate<Context>,
  context: Context
): boolean {
  if (isSinglePredicate(predicate)) {
    return evaluateSingle(predicate, context)
  } else if (isGroupPredicate(predicate)) {
    return evaluateGroup(predicate, context)
  } else {
    throw new Error(`eki: Predicate is invalid ${JSON.stringify(predicate)}`)
  }
}

export const forgivingParse = (json: string): string | any => {
  try {
    return JSON.parse(json)
  } catch (e) {
    return json
  }
}

export const toFormula = (json: object | string): string | null => {
  const jsonValue = typeof json === 'string' ? forgivingParse(json) : json

  if (typeof jsonValue !== 'object') {
    return jsonValue
  }

  try {
    const content: any[] = jsonValue?.content[0]?.content
    if (content && content.length) {
      return content
        .map(c => {
          if (c.type === 'text') {
            return c.text
          } else if (c.type === 'shape_attribute') {
            return c.attrs.label
          }
        })
        .join('')
    }
  } catch (e) {
    console.error(e)
    return null
  }

  return null
}

export function isCustomField(input: string): boolean {
  try {
    if (isGroupOperator(input as Operator)) return false
    if (!input) return true
    const jsonValue = typeof input === 'string' ? forgivingParse(input) : input
    return typeof jsonValue === 'object' || input === customFieldKey
  } catch {
    return false
  }
}

export function evaluateMarkupExpr(
  valueExpr: string,
  model?: Record<string, unknown>
): number | null {
  try {
    const exp = toFormula(valueExpr)
    return exp && mathEvaluate(exp, model)
  } catch {
    return null
  }
}

interface OpFunc {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (input: any, value: unknown): boolean
}

const castToNumberOrThrow = (value: unknown): number => {
  if (Number.isNaN(value as number)) {
    throw new Error(`eki: input value ${value} should be a number`)
  }
  return value as number
}

const ops: Record<BasicOperator, OpFunc> = {
  eq: (input, value) => input == value,
  n_eq: (input, value) => !ops.eq(input, value),

  gt: (input, value) => input > castToNumberOrThrow(value),
  gte: (input, value) => input >= castToNumberOrThrow(value),
  lt: (input, value) => input < castToNumberOrThrow(value),
  lte: (input, value) => input <= castToNumberOrThrow(value),

  contains: (input, value) => {
    if (typeof input === 'undefined') {
      return false
    }
    if (input && Array.isArray(value)) {
      return value.every(v => input.includes(v))
    }
    if (input && typeof input.indexOf === 'function') {
      return input.indexOf(value) > -1
    }
    throw new Error(
      `eki: Input ${input} & value ${value} does not support op 'contains'`
    )
  },
  n_contains: (input, value) => !ops.contains(input, value),

  in: (input, value) => {
    if (Array.isArray(value) === false) {
      throw new Error(`eki: Value ${value} needs to be an array for op 'in'`)
    }

    return (value as unknown[]).includes(input)
  },
  n_in: (input, value) => !ops.in(input, value)
}

export function evaluateSingle<Context>(
  predicate: SinglePredicate<Context>,
  context: Context
): boolean {
  if (context == null) {
    throw new Error('eki: Evaluation context cannot be null')
  }

  const func = ops[predicate.op]
  if (!func) {
    throw new Error(`eki: Predicate operator: ${predicate.op} is not supported`)
  }

  const normalizeValue = (value: unknown): unknown => {
    if (typeof value === 'object' && (value as { key: unknown })?.key) {
      return (value as { key: unknown }).key
    }
    if (Array.isArray(value)) {
      return value.map(normalizeValue)
    }
    return value
  }

  const input = predicate.input

  const inputFun =
    typeof input === 'string'
      ? (context: Context) => {
          const customField = isCustomField(input as string)
          return customField
            ? evaluateMarkupExpr(input, context as any)
            : get(context, input as string)
        }
      : input

  return func(inputFun(context), normalizeValue(predicate.value))
}

export function evaluateGroup<Context>(
  predicate: GroupPredicate<Context>,
  context: Context
): boolean {
  if (predicate.predicates.length < 2) {
    throw new Error('eki: MultiPredicate needs to have at least 2 predicates')
  }

  switch (predicate.op) {
    case 'and':
      return predicate.predicates
        .map(pred => evaluate(pred, context))
        .reduce((result, current) => result && current, true)
    case 'or':
      return predicate.predicates
        .map(pred => evaluate(pred, context))
        .reduce((result, current) => result || current, false)
    default:
      throw new Error(
        `eki: Predicate operator: ${predicate.op} is not supported`
      )
  }
}

const fieldTypeOps: Record<FieldType, BasicOperator[]> = {
  string: ['eq', 'n_eq', 'contains', 'n_contains', 'in', 'n_in'],
  list: ['contains', 'n_contains'],
  number: ['eq', 'n_eq', 'in', 'n_in', 'gt', 'gte', 'lt', 'lte'],
  boolean: ['eq', 'n_eq'],
  enum: ['eq', 'n_eq', 'in', 'n_in']
}
export function opsForFieldType(type: FieldType): BasicOperator[] {
  return fieldTypeOps[type]
}

export const basicOps: BasicOperator[] = [
  'eq',
  'n_eq',
  'contains',
  'n_contains',
  'in',
  'n_in'
]

export const groupOps: GroupOperator[] = ['and', 'or']

export function isGroupOperator(op: Operator): op is GroupOperator {
  return groupOps.includes(op as GroupOperator)
}

export function isBasicOperator(op: BasicOperator): op is BasicOperator {
  return basicOps.includes(op as BasicOperator)
}

export function isGroupPredicate<Context>(
  predicate: Predicate<Context>
): predicate is GroupPredicate<Context> {
  return (predicate as GroupPredicate<Context>).predicates !== undefined
}

export function isSinglePredicate<Context>(
  predicate: Predicate<Context>
): predicate is SinglePredicate<Context> {
  return (predicate as SinglePredicate<Context>).input !== undefined
}
