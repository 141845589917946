












import { defineComponent, provide } from '@vue/composition-api'
import Layout from './components/Layout.vue'
import EnvProvider from './components/EnvProvider.vue'
import { MENU_ITEMS, AUTH } from './appConfigs'
import { provideBreadcrumb } from './composables/useBreadcrumb'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { provideAuth } from '@/lib/auth'
import { apolloClient } from '@/lib/graphql'
import { useLoadConfigurator } from '@/composables/useLoadConfigurator'
import { provideI18n } from '@/composables/useI18n'

// @vue/component
export default defineComponent({
  components: {
    Layout,
    EnvProvider
  },

  setup(_props, { root }) {
    provideBreadcrumb()

    provideI18n()

    provideAuth(
      {
        domain: AUTH.domain,
        clientId: AUTH.clientId,

        audience: AUTH.audience,

        // eslint-disable-next-line
        onRedirectCallback: (appState?: any) => {
          if (appState) {
            root.$router.push(
              appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
            )
          } else {
            root.$router.push('/')
          }
        }
      },
      { router: root.$router }
    )

    provide(DefaultApolloClient, apolloClient)

    useLoadConfigurator()

    return {
      menuItems: MENU_ITEMS
    }
  }
})
