<template>
  <div
    v-if="error"
    class="text-center text-red-500 text-base font-italic"
  >
    <slot>
      <div class="flex flex-col items-center">
        Error: {{ error.message || 'Something went wrong' }}
        <a-button
          v-if="retryFunc"
          class="mt-5 btn-secondary-alt flex-grow-0"
          @click="retryFunc"
        >
          Retry
        </a-button>
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'ErrorView',
  props: {
    error: {
      type: [Object, Error],
      default: undefined
    },
    retryFunc: {
      type: Function,
      default: undefined
    }
  }
}
</script>