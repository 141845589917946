import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { getInstance } from '@/lib/auth'
import { getCurrentLocale } from '@/composables/useI18n'

const uploadLink = createUploadLink({
  uri: `${process.env.VUE_APP_API_BASE_URL}graphql`
})

const authLink = setContext(async (_, { headers }) => {
  const token = await getInstance()?.getTokenSilently({})
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const localeLink = setContext(async (_, { headers }) => {
  const currentLocale = getCurrentLocale()
  return {
    headers: {
      ...headers,
      locale: currentLocale
    }
  }
})

const isFile = (value: File | Blob) =>
  (typeof File !== 'undefined' && value instanceof File) ||
  (typeof Blob !== 'undefined' && value instanceof Blob)

//borrow from https://gist.github.com/Billy-/d94b65998501736bfe6521eadc1ab538
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function omitDeep(value: any, key: string): any {
  if (Array.isArray(value)) {
    return value.map(i => omitDeep(i, key))
  } else if (typeof value === 'object' && value !== null && !isFile(value)) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k == key) return newObject
      return Object.assign({ [k]: omitDeep(value[k], key) }, newObject)
    }, {})
  }
  return value
}

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = omitDeep(operation.variables, '__typename')
    }
    return forward(operation)
  })
}

const omitTypenameLink = createOmitTypenameLink()

const appLink = ApolloLink.from([
  omitTypenameLink,
  authLink,
  localeLink,
  uploadLink
])

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'EnvMaterial':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return `${object.__typename}:${object.id}/${(object as any).env}` // use the `key` field as the identifier
      case 'EnvThickness':
        return null
      case 'EnvAdhesive':
        return null
      default:
        return defaultDataIdFromObject(object) // fall back to default handling
    }
  }
})

export const apolloClient = new ApolloClient({
  link: appLink,
  cache
})
