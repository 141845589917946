
























import { defineComponent } from '@vue/composition-api'
import { useI18n, locales, defaultLocale } from '@/composables/useI18n'
import type { Locale } from '@/composables/useI18n'

export default defineComponent({
  name: 'LangSwitcher',

  props: {
    value: {
      type: String,
      default: 'nl',
      validator: val => val && locales.includes(val)
    },
    theme: {
      type: String,
      default: 'dark',
      validator: val => ['dark', 'light'].includes(val)
    }
  },

  setup() {
    const { currentLocale, updateLocale } = useI18n()
    const options = locales.map((l: Locale) => ({
      icon:
        require(`../assets/images/flags/${l}.svg`),
      value: l
    }))

    return {
      defaultLocale,
      currentLocale,
      updateLocale,
      options
    }
  }
})
