/* eslint-disable */

/// This is taken from vue-test-utils.
/// Before 3.0 is out we need this to prevent global Vue pollution

import Vue, { VueConstructor } from 'vue'
import cloneDeep from 'ramda/src/clone'
import {
  RuleCondition,
  ConditionKey,
  PredicateRuleTree,
  PredicateRuleTreeLocal
} from '@/types/material'
import { Spin } from 'ant-design-vue'
import equals from 'ramda/src/equals'
import map from 'ramda/src/map'
import {
  Predicate,
  SinglePredicate,
  GroupPredicate,
  isCustomField,
  toFormula,
  isSinglePredicate,
  isGroupPredicate
} from '@eki/predicate-kit'
import { v4 as uuidv4 } from 'uuid'
import { ops, groupOps } from '@/components/predicate/PredicateComposer'

export function createVue(_Vue = Vue): VueConstructor<Vue> {
  const instance = _Vue.extend() as any

  // clone global APIs
  Object.keys(_Vue).forEach(key => {
    if (!instance.hasOwnProperty(key)) {
      const original = (_Vue as any)[key]
      // cloneDeep can fail when cloning Vue instances
      // cloneDeep checks that the instance has a Symbol
      // which errors in Vue < 2.17 (https://github.com/vuejs/vue/pull/7878)
      try {
        instance[key] =
          typeof original === 'object' ? cloneDeep(original) : original
      } catch (e) {
        instance[key] = original
      }
    }
  })

  // config is not enumerable
  instance.config = cloneDeep(Vue.config)

  instance.config.errorHandler = Vue.config.errorHandler

  // option merge strategies need to be exposed by reference
  // so that merge strats registered by plugins can work properly
  instance.config.optionMergeStrategies = Vue.config.optionMergeStrategies

  // make sure all extends are based on this instance.
  // this is important so that global components registered by plugins,
  // e.g. router-link are created using the correct base constructor
  instance.options._base = instance

  // compat for vue-router < 2.7.1 where it does not allow multiple installs
  if (instance._installedPlugins && instance._installedPlugins.length) {
    instance._installedPlugins.length = 0
  }
  const use = instance.use
  instance.use = (plugin: any, ...rest: any[]) => {
    if (plugin.installed === true) {
      plugin.installed = false
    }
    if (plugin.install && plugin.install.installed === true) {
      plugin.install.installed = false
    }
    use.call(instance, plugin, ...rest)
  }
  return instance
}

export function sortCondition<
  Cond extends RuleCondition,
  T extends { condition: Cond }
>(a: T, b: T, order: ConditionKey[] = ['adhesive', 'thickness', 'width']) {
  const getKeys = (cond: Cond) => {
    const keys = Object.keys(cond) as ConditionKey[]
    return keys
      .filter(key => !key.startsWith('_'))
      .slice()
      .sort((lhs, rhs) => order.indexOf(lhs) - order.indexOf(rhs))
  }
  const aKeys = getKeys(a.condition)
  const bKeys = getKeys(b.condition)

  if (!equals(aKeys, bKeys)) {
    throw new Error(`eki: rule condition are different a ${aKeys}, b ${bKeys}`)
  }

  return aKeys.reduce((acc, key) => {
    const order = (a.condition[key] as number) - (b.condition[key] as number)
    return acc || order
  }, 0)
}
// eslint-disable-next-line
export function asyncComponent(component: () => any): any {
  return () => ({
    component: component(),
    loading: Spin
  })
}

export function toOption(item: {
  id: number
  name: string
  inapplicable?: boolean
  base?: number
  markup?: number
  code?: string | number
}): {
  label: string
  value: number
  inapplicable?: boolean
  base?: number
  markup?: number
  code?: string | number
} {
  return {
    label: item.name,
    value: item.id,
    inapplicable: item.inapplicable,
    base: item.base,
    markup: item.markup,
    code: item.code
  }
}

export const resolveValue = (value: any) => {
  if (value == null) {
    return null
  }

  if (typeof value === 'object') {
    return value.value ?? value.invariant
  } else {
    return value
  }
}

export const sanitizeValues = <T>(obj: Record<string, T>) => {
  return Object.entries(obj)
    .filter(([key]) => !key.startsWith('_'))
    .reduce((acc, [_key, value]) => ({ ...acc, [_key]: value }), {})
}

export const resolveValues = (obj: any) => {
  return map(resolveValue, obj)
}

export const extractGraphQLErrorMessage = (error: any) => {
  if (error.graphQLErrors) {
    // simply return the first error
    return error.graphQLErrors[0].message
  }

  // return null if it's not a graphql error
  return null
}

export function normalizeCondition(
  condition: string | Predicate<unknown>
): Predicate<unknown> {
  if (typeof condition === 'string') {
    return JSON.parse(condition) as Predicate<unknown>
  }
  return condition
}

export function serializeCondition(
  condition: string | Predicate<unknown>
): string {
  if (typeof condition === 'string') {
    return condition
  }
  return JSON.stringify(condition)
}

export function normalizePredicateRules(
  rules: [{ condition: string | Predicate<unknown> }]
) {
  return rules.map(r => ({
    ...r,
    condition: normalizeCondition(r.condition)
  }))
}

export function serializePredicateRules(
  rules: [{ condition: Predicate<unknown> }]
) {
  return rules.map(r => ({
    ...r,
    condition: JSON.stringify(r.condition)
  }))
}

export function normalizePredicateRuleTree(
  rules: PredicateRuleTree[]
): PredicateRuleTreeLocal[] {
  return rules.map(r => ({
    ...r,
    id: r.id || uuidv4(),
    showProductAmount: !!r.amount || !!r.amountExpr,
    useAmountFormula: !r.amount,
    useValue: !!r.value,
    useOutsideDiameterFormula: !!r.outsideDiameterExpr,
    activeKey: null,
    isRuleNameEditing: false,
    condition: normalizeCondition(r.condition),
    rules: r.rules && normalizePredicateRuleTree(r.rules)
  }))
}

export function serializePredicateRuleTree(
  rules: PredicateRuleTreeLocal[]
): PredicateRuleTree[] {
  return rules.map(r => {
    return {
      condition: serializeCondition(r.condition),
      ruleName: r.ruleName,
      rules: r.rules && serializePredicateRuleTree(r.rules),
      ...(r.showProductAmount
        ? r.useAmountFormula
          ? { amountExpr: r.amountExpr }
          : { amount: r.amount }
        : {}),
      ...(r.useValue ? { value: r.value } : {}),
      ...(r.useOutsideDiameterFormula
        ? { outsideDiameterExpr: r.outsideDiameterExpr }
        : {})
    }
  })
}

export function getConditionName(condition: SinglePredicate<unknown>): unknown {
  const op = ops.find(op => condition.op == op.key)
  if (!!op) {
    const customField = isCustomField(condition.input as string)
    const _input = toFormula(condition.input)
    const input = customField && _input ? `"${_input}"` : _input || ''

    if (typeof condition.value === 'string') {
      return condition.value
        ? `${input} ${op.label} ${condition.value}`
        : `${input}`
    } else if (typeof condition.value === 'number') {
      return `${input} ${op.label} ${condition.value}`
    }
    return `${input}`
  } else {
    return ''
  }
}

export function getPredicateRuleName(condition: Predicate<unknown>): unknown {
  const groupOp = groupOps.find(groupOp => condition.op == groupOp.key)
  if (!!groupOp) {
    return (
      '(' +
      (condition as GroupPredicate<unknown>).predicates
        ?.map(item => getPredicateRuleName(item))
        .join(` ${condition.op} `) +
      ')'
    )
  }
  return getConditionName(condition as SinglePredicate<unknown>)
}

export function validatePredicate(rule: Predicate<any>): boolean {
  const condition = normalizeCondition(rule)
  if (isSinglePredicate(condition)) {
    return !!condition.value
  }
  if (isGroupPredicate(condition)) {
    return condition.predicates.every(item => {
      return validatePredicate(item)
    })
  }
  return true
}

export function validatePackageRules(rules: PredicateRuleTreeLocal[]): boolean {
  return rules.every(r => {
    if (r.rules && r.rules.length) {
      return validatePackageRules(r.rules)
    } else {
      return validatePredicate(r.condition)
    }
  })
}

export function roundup(
  num?: number | null,
  numDigits = 4
): number | null | undefined {
  if (num == null) {
    return num
  }
  const fixedDigits = numDigits + 1
  const multiplier = Math.pow(10, numDigits)

  num = Number(num.toFixed(fixedDigits))
  return (
    num &&
    Math.ceil(Number((num * multiplier).toFixed(fixedDigits))) / multiplier
  )
}
