import {
  Affix,
  Avatar,
  Button,
  Checkbox,
  Empty,
  Spin,
  Drawer,
  Layout,
  Menu,
  Icon,
  Breadcrumb,
  Row,
  Card,
  Col,
  Modal,
  Table,
  Input,
  Select,
  InputNumber,
  Tag,
  Tabs,
  Form,
  Upload,
  Tooltip,
  Popconfirm,
  message,
  notification,
  Dropdown,
  Badge,
  Collapse,
} from 'ant-design-vue'
import { AntdComponent } from 'ant-design-vue/types/component'
import { VueConstructor } from 'vue'

export default {
  install(Vue: VueConstructor): void {
    const components: typeof AntdComponent[] = [
      Affix,
      Avatar,
      Empty,
      Button,
      Checkbox,
      Spin,
      Drawer,
      Dropdown,
      Layout,
      Icon,
      Menu,
      Breadcrumb,
      Row,
      Col,
      Card,
      Table,
      Input,
      Select,
      InputNumber,
      Tag,
      Tabs,
      Form,
      Upload,
      Popconfirm,
      Tooltip,
      Select,
      Badge,
      Modal,
      Collapse,
    ]
    components.forEach(component => {
      component.install(Vue)
    })

    Vue.prototype.$confirm = Modal.confirm
    Vue.prototype.$message = message
    Vue.prototype.$warning = Modal.warning
    Vue.prototype.$notification = notification

    Spin.setDefaultIndicator({
      indicator: (h: Function) => {
        return h('a-icon', {
          props: {
            type: 'loading'
          },
          style: {
            fontSize: '24px',
            color: 'rgba(0, 0, 0, 0.65)'
          }
        })
      }
    })
  }
}
