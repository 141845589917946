import PageHeader from '@/components/PageHeader.vue'
import EditableSection from '@/components/EditableSection.vue'
import Panel from '@/components/Panel.vue'
import ErrorView from '@/components/ErrorView.vue'
import Hint from '@/components/Hint.vue'
import EnvRouterLink from '@/components/EnvRouterLink.vue'
import { VueConstructor } from 'vue'

export default {
  install(Vue: VueConstructor): void {
    /* eslint-disable vue/component-definition-name-casing */
    Vue.component('eki-page-header', PageHeader)
    Vue.component('eki-editable-section', EditableSection)
    Vue.component('eki-panel', Panel)
    Vue.component('eki-error-view', ErrorView)
    Vue.component('eki-hint', Hint)
    Vue.component('eki-env-link', EnvRouterLink)
    /* eslint-enable vue/component-definition-name-casing */
  }
}
