/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  InjectionKey,
  ref,
  Ref,
  provide,
  inject,
  computed
} from '@vue/composition-api'

// TODO:
export const locales = ['nl', 'en', 'de'] as const
export const defaultLocale = 'nl'

export type Locale = typeof locales[number]

export type ReturnValue = {
  currentLocale: Ref<Locale>
  isDefault: Ref<boolean>
  updateLocale: (locale: Locale) => void
}

export const key: InjectionKey<ReturnValue> = Symbol('i18n')

const CURRENT_LOCALE = 'current_locale'

export function getCurrentLocale() {
  return (window.localStorage.getItem(CURRENT_LOCALE) as Locale) ?? defaultLocale
}

export function provideI18n() {
  const savedCurrentLocale: Locale =
    (window.localStorage.getItem(CURRENT_LOCALE) as Locale) ?? defaultLocale
  const currentLocale = ref(savedCurrentLocale)

  provide(key, {
    currentLocale,
    isDefault: computed(() => currentLocale.value === defaultLocale),
    updateLocale: (l: Locale) => {
      window.localStorage.setItem(CURRENT_LOCALE, l)
      currentLocale.value = l
    }
  })
}

export function useI18n(): ReturnValue {
  const result = inject(key)
  if (!result) {
    throw new Error('eki: I18n is not provided')
  }
  return result
}
