import {
  InjectionKey,
  provide,
  inject,
  ref,
  Ref,
  computed,
  getCurrentInstance
} from '@vue/composition-api'

type Result = {
  routes: Readonly<Ref<Readonly<{ path: string; breadcrumbName: string }[]>>>
  showBreadcrumb: Ref<boolean>
  // eslint-disable-next-line
  setCurrentbreadcrumbName: (...args: any[]) => void
  setRoutebreadcrumbName: (path: string, breadcrumbName: string) => void
}

export const key: InjectionKey<Result> = Symbol('breadcrumb')

// Specify breadcrumb for each route by either
// 1. use `setRoutebreadcrumbName` or `setCurrentbreadcrumbName` hook
// 2. specify in route meta
// > NOTE: breadcrumb specified by hook will higher priority than in meta
export function provideBreadcrumb(): void {
  const vm = getCurrentInstance()
  if (!vm) {
    throw new Error(
      '[eki]: vue instance is not found during breadcrumb provision'
    )
  }

  const router = vm.$router
  const route = computed(() => vm.$route)

  // eslint-disable-next-line
  const routeBreadcrumbs: Ref<any> = ref({})
  const setRoutebreadcrumbName = (
    path: string,
    breadcrumbName: string
  ): void => {
    routeBreadcrumbs.value[path] = breadcrumbName
    routeBreadcrumbs.value = {
      ...routeBreadcrumbs.value,
      [path]: breadcrumbName
    }
  }
  // eslint-disable-next-line
  const setCurrentbreadcrumbName = (...args: any[]): void => {
    const [path, name] =
      args.length > 1
        ? args
        : [
            route.value.matched &&
              route.value.matched[route.value.matched.length - 1].path,
            args[0]
          ]

    if (!path) {
      return
    }

    setRoutebreadcrumbName(path, name)
  }

  const routes = computed(() => {
    const matched = route.value?.matched ?? []
    return matched
      .map(route => {
        const breadcrumbName =
          routeBreadcrumbs.value[route.path] ?? route.meta.breadcrumbName

        return (
          breadcrumbName && {
            path: router.resolve({
              name: route.name
            }).resolved.path,
            breadcrumbName
          }
        )
      })
      .filter(item => item != null)
  })

  const showBreadcrumb = computed(() => routes.value.length > 1)

  provide(key, {
    setCurrentbreadcrumbName,
    setRoutebreadcrumbName,
    routes,
    showBreadcrumb
  })
}

export default function useBreadcrumb(): Result {
  const result = inject(key)
  if (result == null) {
    throw new Error('Breadcrumb provider not set')
  }
  return result
}
