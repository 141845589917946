<template>
  <header class="flex items-center text-xl font-semibold mb-4">
    {{ title }}
    <span
      v-if="subtitle"
      class="ml-4 text-sm font-normal text-gray-500"
    >
      {{ subtitle }}
    </span>
    <div
      v-if="$slots['left']"
      class="ml-2"
    >
      <slot name="left" />
    </div>
    <div
      v-if="$slots['right']"
      class="ml-auto"
    >
      <slot name="right" />
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    }
  }
}
</script>