

























import useBreadcrumb from '@/composables/useBreadcrumb'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Breadcrumb',

  setup() {
    const { routes, showBreadcrumb } = useBreadcrumb()

    return {
      showBreadcrumb,
      routes
    }
  }
})
