





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EditableSection',

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      required: true
    },
    linkText: {
      type: String,
      default: 'Configure'
    },
    editingRoute: {
      type: Object,
      required: true
    }
  }
})
