export const MENU_ITEMS = [
  { title: 'Materials', icon: 'database', route: { name: 'material' } },
  // { title: 'Shape', icon: 'appstore', route: { name: 'shapes' } },
  { title: 'Global', icon: 'bars', route: { name: 'global-attrs' } },
  { title: 'Machines', icon: 'gold', route: { name: 'machine' } },
  { title: 'Prices', icon: 'dollar', route: { name: 'prices' } },
  { title: 'Package Preset', icon: 'inbox', route: { name: 'package-preset' } }
] as const

export const AUTH = {
  // eslint-disable-next-line
  domain: process.env.VUE_APP_AUTH0_DOMAIN!,
  // eslint-disable-next-line
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID!,
  // eslint-disable-next-line
  audience: process.env.VUE_APP_AUTH0_AUDIENCE
}

export const SHAPES = [
  {
    name: 'Tape',
    code: 'S1',
    image: require('@/assets/images/shapes/S1.png')
  },
  {
    name: 'Roll',
    code: 'S2',
    image: require('@/assets/images/shapes/S2.png')
  },
  {
    name: 'Sheet',
    code: 'S3',
    image: require('@/assets/images/shapes/S3.png')
  },
  {
    name: 'Coil',
    code: 'S4',
    image: require('@/assets/images/shapes/S4.png')
  },
  {
    name: 'Block',
    code: 'S5',
    image: require('@/assets/images/shapes/S5.png')
  },
  {
    name: 'Disc',
    code: 'S9',
    image: require('@/assets/images/shapes/S9.png')
  },
  {
    name: 'Ring',
    code: 'S10',
    image: require('@/assets/images/shapes/S10.png')
  },
  {
    name: 'Ring with holes',
    code: 'S11',
    image: require('@/assets/images/shapes/S11.png')
  }
] as const

const defaultBasics = {
  adhesives: [
    {
      id: 75,
      code: 'none'
    },
    {
      id: 76,
      code: 'one_side'
    },
    {
      id: 77,
      code: 'two_side'
    }
  ],
  thicknesses: [
    {
      adhesive: {
        id: 75,
        code: 'none',
        name: '0 side'
      },
      values: [
        {
          id: 210,
          code: 2,
          name: '2mm'
        },
        {
          id: 211,
          code: 3,
          name: '3mm'
        },
        {
          id: 212,
          code: 4,
          name: '4mm'
        },
        {
          id: 213,
          code: 5,
          name: '5mm'
        },
        {
          id: 214,
          code: 6,
          name: '6mm'
        },
        {
          id: 215,
          code: 7,
          name: '7mm'
        },
        {
          id: 216,
          code: 8,
          name: '8mm'
        },
        {
          id: 217,
          code: 10,
          name: '10mm'
        },
        {
          id: 218,
          code: 12,
          name: '12mm'
        },
        {
          id: 219,
          code: 15,
          name: '15mm'
        }
      ]
    }
  ]
}

export const MATERIAL = {
  id: 'xxx-xxx-xxx',
  meta: {
    productNumber: '',
    shapes: [
      {
        name: 'Tape',
        code: 'S1',
        image: require('@/assets/images/shapes/S1.png'),
        enabled: true
      },
      {
        name: 'Roll',
        code: 'S2',
        image: require('@/assets/images/shapes/S2.png'),
        enabled: true
      },
      {
        name: 'Sheet',
        code: 'S3',
        image: require('@/assets/images/shapes/S3.png'),
        enabled: true
      },
      {
        name: 'Coil',
        code: 'S4',
        image: require('@/assets/images/shapes/S4.png'),
        enabled: true
      },
      {
        name: 'Block',
        code: 'S5',
        image: require('@/assets/images/shapes/S5.png'),
        enabled: true
      },
      {
        name: 'Disc',
        code: 'S9',
        image: require('@/assets/images/shapes/S9.png'),
        enabled: true
      },
      {
        name: 'Ring',
        code: 'S10',
        image: require('@/assets/images/shapes/S10.png'),
        enabled: true
      },
      {
        name: 'Ring with holes',
        code: 'S11',
        image: require('@/assets/images/shapes/S11.png'),
        enabled: true
      }
    ],
    adhesives: ['OneSide', 'TwoSide', 'None'],
    thickness: [2, 3, 4, 5, 6, 7],
    priceFactors: {
      P: [
        {
          name: 'P1', // unique
          value: []
        },
        {
          name: 'P2',
          value: []
        }
      ],
      Mx: [
        {
          name: 'mx1', // unique
          value: {
            formula: '1',
            min: 1,
            max: null
          }
        },
        {
          name: 'mx2',
          value: {
            formula: JSON.stringify({
              type: 'doc',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    { type: 'text', text: '1 + ' },
                    { type: 'shape_attribute', attrs: { id: 1, label: 'x' } }
                  ]
                }
              ]
            }),
            min: 1,
            max: null
          }
        }
      ]
    }
  },
  shapes: {
    S1: {
      basic: defaultBasics,
      attributes: {
        width: {
          value: {
            defaultValue: {
              a: 1000,
              b: 20,
              c: 30
            },
            rules: [
              {
                condition: {
                  adhesive: 'one_side',
                  thickness: 2
                },
                value: { a: 500, b: 20, c: 30 }
              }
            ]
          }
        },
        length: {
          value: {
            rules: [
              {
                condition: {
                  adhesive: 'one_side',
                  thickness: 2
                },
                value: {
                  options: [50, 20],
                  defaultOption: 20
                }
              },
              {
                condition: {
                  adhesive: 'one_side',
                  thickness: 3
                },
                value: {
                  options: [50, 20],
                  defaultOption: 50
                }
              }
            ]
          }
        }
      },
      price: {
        P: 'P1',
        M: 'M1'
      }
    },
    S2: {
      basic: defaultBasics,
      attributes: {
        width: {
          value: 2
        },
        length: {
          value: {
            rules: [
              {
                condition: {
                  adhesive: 'one_side',
                  thickness: 2
                },
                value: {
                  options: [50, 20],
                  defaultOption: 20
                }
              },
              {
                condition: {
                  adhesive: 'one_side',
                  thickness: 3
                },
                value: {
                  options: [50, 20],
                  defaultOption: 50
                }
              }
            ]
          }
        }
      },
      price: {
        P: 'P1',
        M: 'M1'
      }
    },
    S3: {
      basic: defaultBasics,
      attributes: {
        width: {
          value: 10
        },
        length: {
          value: 3
        }
      },
      price: {
        P: 'P2',
        M: 'M1'
      }
    },
    S4: {
      basic: defaultBasics,
      attributes: {
        width: {
          value: {
            defaultValue: {
              a: 1000,
              b: 20,
              c: 30
            },
            rules: [
              {
                condition: {
                  adhesive: 'one_side',
                  thickness: 2
                },
                value: { a: 500, b: 20, c: 30 }
              }
            ]
          }
        },
        length: {
          value: {
            rules: [
              {
                condition: {
                  adhesive: 'one_side',
                  thickness: 2
                },
                value: {
                  options: [50, 20],
                  defaultOption: 20
                }
              },
              {
                condition: {
                  adhesive: 'one_side',
                  thickness: 3
                },
                value: {
                  options: [50, 20],
                  defaultOption: 50
                }
              }
            ]
          }
        }
      },
      price: {
        P: 'P2',
        M: 'M1'
      }
    },
    S5: {
      basic: defaultBasics,
      attributes: {},
      price: {
        P: 'P2',
        M: 'M1'
      }
    },
    S9: {
      basic: defaultBasics,
      attributes: {},
      price: {
        P: 'P2',
        M: 'M1'
      }
    },
    S10: {
      basic: defaultBasics,
      attributes: {},
      price: {
        P: 'P2',
        M: 'M1'
      }
    },
    S11: {
      basic: defaultBasics,
      attributes: {},
      price: {
        P: 'P2',
        M: 'M1'
      }
    }
  },
  status: 'PUBLISHED'
}
