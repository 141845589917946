import { BasicOperator, GroupOperator } from '@eki/predicate-kit'

export const ops: { label: string; key: BasicOperator }[] = [
  { label: 'is', key: 'eq' },
  { label: 'is not', key: 'n_eq' },

  { label: '>', key: 'gt' },
  { label: '>=', key: 'gte' },

  { label: '<', key: 'lt' },
  { label: '<=', key: 'lte' },

  { label: 'contains', key: 'contains' },
  { label: 'not contain', key: 'n_contains' },

  { label: 'is in', key: 'in' },
  { label: 'is not in', key: 'n_in' }
]

export const groupOps: {
  label: string
  key: GroupOperator
}[] = [
  { label: 'If all of the following conditions are met', key: 'and' },
  { label: 'If any of the following conditions are met', key: 'or' }
]

export const customField = [
  { label: 'Add a dynamic expression', key: 'custom' }
]
