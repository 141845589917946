import { RouteConfig } from 'vue-router'
import RouteEntry from '@/components/RouteEntry.vue'

export const routes = [
  {
    name: 'auth-error',
    path: '/auth-error',
    component: () => import('./auth-error.vue')
  },
  {
    name: 'material',
    path: '/:env?/materials',
    component: RouteEntry,
    meta: {
      breadcrumbName: 'Materials'
    },
    redirect: { name: 'material.index' },
    children: [
      {
        name: 'material.index',
        path: '',
        component: () => import('./material/material-index.vue')
      },
      {
        name: 'material.new',
        path: 'new',
        component: () => import('./material/material-details.vue'),
        redirect: { name: 'material.new.meta' },
        children: [
          {
            name: 'material.new.shape',
            path: 'shape',
            component: () => import('./material/material-shape.vue')
          },
          {
            name: 'material.new.meta',
            path: 'meta',
            component: () => import('./material/material-meta.vue')
          }
        ]
      },
      {
        name: 'material.details',
        path: ':id',
        component: () => import('./material/material-details.vue'),
        redirect: { name: 'material.details.meta' },
        children: [
          {
            name: 'material.details.shape',
            path: 'shape',
            component: () => import('./material/material-shape.vue')
          },
          {
            name: 'material.details.meta',
            path: 'meta',
            component: () => import('./material/material-meta.vue')
          }
        ]
      }
    ]
  },
  {
    name: 'global-attrs',
    path: '/:env?/global-attrs',
    meta: {
      breadcrumbName: 'Global Attributes'
    },
    component: RouteEntry,
    redirect: { name: 'global-attrs.index' },
    children: [
      {
        name: 'global-attrs.index',
        path: '',
        component: () => import('./global-attrs/global-attrs-index.vue')
      },
      {
        name: 'global-attrs.adhesive.edit',
        path: 'adhesive/edit',
        component: () => import('./global-attrs/edit-global-adhesive.vue')
      },
      {
        name: 'global-attrs.thickness.edit',
        path: 'thickness/edit',
        component: () => import('./global-attrs/edit-global-thickness.vue')
      },
      {
        name: 'global-attrs.price-markup.edit',
        path: 'price-markup/edit',
        component: () => import('./global-attrs/edit-global-price-markup.vue')
      },
      {
        name: 'global-attrs.p0-settings.edit',
        path: 'p0/edit',
        component: () => import('./global-attrs/edit-global-p0.vue')
      }
    ]
  },
  {
    name: 'shapes',
    path: '/:env?/shapes',
    component: RouteEntry,
    meta: {
      breadcrumbName: 'Shapes'
    },
    redirect: { name: 'shapes.index' },
    children: [
      {
        name: 'shapes.index',
        path: '',
        component: () => import('./shapes/shapes-index.vue')
      }
    ]
  },
  {
    name: 'environments',
    path: '/environments',
    component: () => import('./environments.vue')
  },
  {
    name: 'prices',
    path: '/prices',
    component: RouteEntry,
    meta: {
      breadcrumbName: 'Prices'
    },
    redirect: { name: 'prices.index' },
    children: [
      {
        name: 'prices.index',
        path: '',
        component: () => import('./prices/index.vue')
      },
      {
        name: 'prices.price-list',
        path: 'price-list',
        component: () => import('./prices/price-list.vue')
      }
    ]
  },
  {
    name: 'machine',
    path: '/machines',
    component: RouteEntry,
    meta: {
      breadcrumbName: 'Machines'
    },
    redirect: { name: 'machine.index' },
    children: [
      {
        name: 'machine.index',
        path: '',
        component: () => import('./machines/machine-index.vue')
      },
      {
        name: 'machine.new',
        path: 'new',
        meta: {
          breadcrumbName: 'Create machine'
        },
        component: () => import('./machines/machine-details.vue')
      },
      {
        name: 'machine.details',
        path: ':id',
        meta: {
          breadcrumbName: 'Edit machine'
        },
        component: () => import('./machines/machine-details.vue')
      }
    ]
  },
  {
    name: 'package-preset',
    path: '/package-preset',
    component: RouteEntry,
    meta: {
      breadcrumbName: 'PackagePreset'
    },
    redirect: { name: 'package-preset.index' },
    children: [
      {
        name: 'package-preset.index',
        path: '',
        component: () => import('./package-preset/package-preset-index.vue')
      },
      {
        name: 'package-preset.custom',
        path: 'custom',
        component: () => import('./package-preset/package-preset-custom.vue')
      },
      {
        name: 'package-preset.new',
        path: 'new',
        component: () => import('./package-preset/package-preset-details.vue')
      },
      {
        name: 'package-preset.edit',
        path: ':id',
        meta: {
          breadcrumbName: 'Edit package'
        },
        component: () => import('./package-preset/package-preset-details.vue')
      }
    ]
  },
  {
    name: 'playground',
    path: '/_playground',
    component: () => import('./_playground.vue')
  },
  { path: '', redirect: { name: 'material.index' } },
  { path: '*', name: 'not-found', component: () => import('./404.vue') }
] as RouteConfig[]
