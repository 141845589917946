










import { defineComponent, computed } from '@vue/composition-api'
import { useEnv } from '@/composables/useEnvironment'
import { Location } from 'vue-router'

export default defineComponent({
  name: 'EnvRouterLink',

  setup(_props, { attrs }) {
    const { withEnv } = useEnv()

    const to = computed(() => withEnv((attrs.to as unknown) as Location))

    return {
      withEnv,
      to
    }
  }
})
