import { onMounted, ref, Ref } from '@vue/composition-api'
import { createVue } from '@/lib/utils'

const Vue = createVue()
const WIDGET_URL = '/product-configurator/eki-preview-configurator.min.js'

export function useLoadConfigurator() {
  const isLoading = ref(false)
  const error: Ref<null | unknown> = ref(null)

  onMounted(async () => {
    if (document.querySelector(`script[src="${WIDGET_URL}"]`)) {
      return
    }
    isLoading.value = true
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).Vue = Vue
    const widget = document.createElement('script')
    widget.setAttribute('src', WIDGET_URL)
    document.head.appendChild(widget)
    widget.async = true
    widget.defer = true
    widget.onload = function() {
      isLoading.value = false
    }
    widget.onerror = function(e) {
      isLoading.value = false
      error.value = error
      console.error(e)
    }

    return {
      isLoading,
      error
    }
  })
}
