/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  ref,
  Ref,
  InjectionKey,
  provide,
  inject,
  watch
} from '@vue/composition-api'
import equals from 'ramda/src/equals'
import createAuth0Client, {
  Auth0Client,
  RedirectLoginOptions,
  PopupLoginOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  IdToken,
  Auth0ClientOptions
} from '@auth0/auth0-spa-js'
import Router, { NavigationGuard } from 'vue-router'

/** Define a default action to perform after authentication */
// eslint-disable-next-line
const DEFAULT_REDIRECT_CALLBACK = (_: any) =>
  window.history.replaceState({}, document.title, window.location.pathname)

export type AuthInstance = {
  loading: Ref<boolean>
  isAuthenticated: Ref<boolean>
  user: Ref<{}>
  popupOpen: Ref<boolean>
  error: Ref<null | Error>
  logout: (o?: LogoutOptions) => void | undefined
  loginWithPopup: (o: PopupLoginOptions) => Promise<void>
  getTokenWithPopup: (
    o: GetTokenWithPopupOptions
  ) => Promise<string> | undefined
  getTokenSilently: (o: GetTokenSilentlyOptions) => Promise<unknown> | undefined
  getIdTokenClaims: (o: GetIdTokenClaimsOptions) => Promise<IdToken> | undefined
  handleRedirectCallback: () => Promise<void>
  loginWithRedirect: (o: RedirectLoginOptions) => Promise<void> | undefined
}

let instance: AuthInstance | null = null

// eslint-disable-next-line
let _options: any | null = null

// Make it readonly
export const getInstance = () => instance

type AuthClientOptions = Omit<
  Auth0ClientOptions,
  'client_id' | 'redirect_uri'
> & { clientId: string; redirectUri?: string }

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const setupAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}: AuthClientOptions): AuthInstance => {
  const opts: AuthClientOptions = {
    onRedirectCallback,
    redirectUri,
    ...options
  }
  const isSameConfig = !!_options && equals(opts, _options)

  if (instance && isSameConfig) {
    return instance
  }
  _options = opts
  if (opts.domain == null) {
    throw new Error(`auth0's domain cannot be null`)
  }
  if (opts.clientId == null) {
    throw new Error(`auth0's clientId cannot be null`)
  }

  const loading = ref(true)
  const isAuthenticated = ref(false)
  const user = ref({})
  const auth0Client: Ref<null | Auth0Client> = ref(null)
  const popupOpen = ref(false)
  const error: Ref<null | Error> = ref(null)

  const loginWithPopup = async (o: PopupLoginOptions) => {
    const client = auth0Client.value
    if (client == null) {
      return
    }

    popupOpen.value = true

    try {
      await client.loginWithPopup(o)
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
    } finally {
      popupOpen.value = false
    }

    user.value = await client.getUser()
    isAuthenticated.value = true
  }

  /** Handles the callback when logging in using a redirect */
  const handleRedirectCallback = async () => {
    const client = auth0Client.value
    if (client == null) {
      return
    }

    loading.value = true
    try {
      await client.handleRedirectCallback()
      user.value = await client.getUser()
      isAuthenticated.value = true
    } catch (e) {
      error.value = e as Error
    } finally {
      loading.value = false
    }
  }

  /** Authenticates the user using the redirect method */
  const loginWithRedirect = (o: RedirectLoginOptions) => {
    return auth0Client.value?.loginWithRedirect(o)
  }

  /** Returns all the claims present in the ID token */
  const getIdTokenClaims = (o: GetIdTokenClaimsOptions) => {
    return auth0Client.value?.getIdTokenClaims(o)
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  const getTokenSilently = (o: GetTokenSilentlyOptions) => {
    return auth0Client.value?.getTokenSilently(o)
  }
  /** Gets the access token using a popup window */

  const getTokenWithPopup = (o: GetTokenWithPopupOptions) => {
    return auth0Client.value?.getTokenWithPopup(o)
  }
  /** Logs the user out and removes their session on the authorization server */
  const logout = (o?: LogoutOptions) => {
    return auth0Client.value?.logout({ returnTo: window.location.origin, ...o })
  }

  const init = async () => {
    // Create a new instance of the SDK client using members of the given options object
    auth0Client.value = await createAuth0Client({
      domain: options.domain,
      // eslint-disable-next-line
      client_id: options.clientId,
      audience: options.audience,
      // eslint-disable-next-line
      redirect_uri: redirectUri
    })

    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes('state=') &&
        (window.location.search.includes('code=') ||
          window.location.search.includes('error='))
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await auth0Client.value.handleRedirectCallback()

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        onRedirectCallback(appState)
      }
    } catch (e) {
      error.value = e as Error
      console.error(e)
    } finally {
      // Initialize our internal authentication state
      isAuthenticated.value = await auth0Client.value.isAuthenticated()
      user.value = await auth0Client.value.getUser()
      loading.value = false
    }
  }

  init()

  instance = {
    loading,
    isAuthenticated,
    user,
    popupOpen,
    error,

    loginWithPopup,
    getTokenWithPopup,
    getTokenSilently,
    getIdTokenClaims,
    handleRedirectCallback,
    loginWithRedirect,
    logout
  }

  return instance
}

export const key: InjectionKey<AuthInstance> = Symbol('auth')

export const provideAuth = (
  options: AuthClientOptions,
  { router }: { router?: Router } = {}
) => {
  const authInstance = setupAuth0(options)

  if (router) {
    useGuardRouter(router, authInstance)
  }

  provide(key, authInstance)
}

export const useAuth = () => {
  const result = inject(key)
  if (result == null) {
    throw new Error(
      'No Auth provider found, please provide it first using `provideAuth({...})`'
    )
  }
  return result
}
// eslint-disable-next-line
function useGuardRouter(router: Router, authInstance: AuthInstance) {
  const { isAuthenticated, loading, loginWithRedirect, error } = authInstance

  const guard: NavigationGuard = (to, _from, next) => {
    if (to.name === 'auth-error') {
      return next()
    }

    const fn = () => {
      if (error.value != null) {
        return next({ name: 'auth-error' })
      }
      if (isAuthenticated.value === false) {
        loginWithRedirect({ appState: { targetUrl: to.fullPath } })
      } else {
        next()
      }
    }

    if (!loading.value) {
      return fn()
    }

    watch(loading, loading => {
      if (!loading) {
        fn()
      }
    })
  }

  router.beforeResolve(guard)

  router.beforeEach(guard)
}
