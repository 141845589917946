






























































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Panel',

  props: {
    title: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: [Error, Object],
      required: false,
      default: null
    },
    collapsible: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      internalError: null
    }
  },
  // eslint-disable-next-line
  errorCaptured(this: any, err, vm, info) {
    this.internalError = err
  }
})
