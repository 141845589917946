<template>
  <a-select
    v-if="isVisible"
    v-model="env"
    default-active-first-option
    class="NavBarSwitcher"
    :class="{
      'NavBarSwitcher--sub': isNonMaster,
      'NavBarSwitcher--light': theme === 'light'
    }"
    dropdown-class-name="NavBarSwitcherDropdown"
  >
    <a-select-option value="master">
      Master
    </a-select-option>
    <a-select-option
      v-for="environment in environments"
      :key="environment.id"
      :value="environment.name"
    >
      {{ environment.name }}
    </a-select-option>

    <template #suffixIcon>
      <svg
        version="1.1"
        viewBox="0 0 44 26"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path d="M0,3.01097541 C0,1.3061045 1.39293975,0.000963203907 2.98808074,0.000963203907 C3.78563132,0.000963203907 4.58322174,0.302747028 5.08120532,0.902401661 L22.012647,18.6604704 L38.9440886,0.902401661 C40.1384524,-0.300800554 42.0331804,-0.300800554 43.1263539,0.902401661 C44.2235113,2.00767814 44.3207177,3.81047479 43.2275043,5.01769035 L24.2025397,25.0844384 C23.1053823,26.2876406 21.2146383,26.2876406 20.0202744,25.1863374 L19.919124,25.0844384 L0.797550579,5.01769035 C0.299567001,4.51602165 0,3.81448814 0,3.01101555 L0,3.01097541 Z" />
      </svg>
    </template>
  </a-select>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useEnv, withEnv } from '@/composables/useEnvironment'

export default defineComponent({
  name: 'EnvSwitcher',

  props: {
    theme: {
      type: String,
      default: 'dark',
      validator: val => ['dark', 'light'].includes(val)
    }
  },

  setup(_, { root }) {
    const { currentEnv, environments, isMaster } = useEnv()
    const env = computed({
      get: () => currentEnv.value?.name,
      set: val => {
        root.$router.replace(withEnv(root.$route, val))
      }
    })

    const inapplicableRoutes = [
      'environments',
      'shapes',
      'material.new',
      'machine',
      'prices'
    ]
    const isVisible = computed(
      () =>
        root.$route &&
        root.$route.matched.every(
          route => !inapplicableRoutes.includes(route.name)
        )
    )
    return {
      env,
      environments,
      isVisible,
      isNonMaster: computed(() => !isMaster.value)
    }
  }
})
</script>
