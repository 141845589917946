

















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Hint',

  props: {
    content: {
      type: String,
      default: ''
    }
  }
})
