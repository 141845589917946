/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  InjectionKey,
  Ref,
  computed,
  provide,
  inject
} from '@vue/composition-api'
import { Location } from 'vue-router'
import pickBy from 'ramda/src/pickBy'
import environmentsQuery from '@/lib/graphql/environments.query.gql'
import upsertEnvironmentMutation from '@/lib/graphql/upsertEnvironment.mutation.gql'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { Dictionary, Route } from 'vue-router/types/router'
import { useAuth } from '@/lib/auth'

type Env =
  | {
      name: 'master'
      id: null
    }
  | {
      name: string
      id: number
    }
type ReturnValue = {
  currentEnv: Readonly<Ref<Readonly<Env | null>>>
  environments: Readonly<Ref<Readonly<Env[]>>>
  error: Ref<Error>
  loading: Readonly<Ref<boolean>>
  withEnv: <L extends Location | string>(route: L) => L
  isMaster: Ref<boolean>
  isEnvLoaded: Readonly<Ref<boolean>>
}

export const key: InjectionKey<ReturnValue> = Symbol('environment')

export function provideEnv(route: Readonly<Ref<Location | Route>>) {
  const { loading: authLoading } = useAuth()
  const { result, loading, error } = useQuery(
    environmentsQuery,
    undefined,
    computed(() => ({
      enabled: !authLoading.value
    }))
  )
  const environments = useResult(result, []) as Ref<Env[]>
  const currentEnv = computed(() => {
    const env = route.value.params?.env
    if (env) {
      const found = environments.value.find(
        environment => environment.name === env
      )
      return found || null
    } else {
      return { name: 'master' } as Env
    }
  })

  const isEnvLoaded = computed(() => currentEnv.value != null)

  const isMaster = computed(() => {
    if (currentEnv.value == null) {
      console.warn('eki: environments is still loading when calling `isMaster`')
    }
    return currentEnv.value?.name === 'master'
  })
  const withEnvFunc = <L extends Location | string>(route: L) => {
    const env = currentEnv.value
    if (env == null) {
      console.warn('eki: environments is still loading when calling `withEnv`')
      return route
    }
    return isMaster.value ? route : withEnv(route, env)
  }

  provide(key, {
    currentEnv,
    isMaster,
    isEnvLoaded,

    environments,
    loading,
    error,

    withEnv: withEnvFunc
  })
}

export function useCreateEnv() {
  return useMutation(upsertEnvironmentMutation, {
    update: (cache, { data: { upsertEnvironment } }) => {
      const data = cache.readQuery({ query: environmentsQuery }) as {
        allEnvironments: Env[]
      }

      data.allEnvironments = [...data.allEnvironments, upsertEnvironment]
      cache.writeQuery({ query: environmentsQuery, data })
    }
  })
}

export function useEnv(): ReturnValue {
  const result = inject(key)
  if (!result) {
    throw new Error('eki: Environment is not provided')
  }
  return result
}

function isLocation(route: Location | string): route is Location {
  return typeof route !== 'string'
}

export function withEnv<L extends Location | string>(
  route: L,
  env: Env | string
): L {
  const normalizedEnv = typeof env === 'string' ? { name: env } : env
  const isMaster = ['master', ''].includes(normalizedEnv.name)
  if (isLocation(route)) {
    const picked = pickBy(
      (_, key) => key !== 'env',
      route.params || {}
    ) as Dictionary<string>
    const params = {
      ...picked,
      ...(isMaster ? {} : { env: normalizedEnv.name })
    }
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(route as any),
      params
    }
  } else {
    return `${isMaster ? '' : '/' + normalizedEnv.name}${route}` as L
  }
}
