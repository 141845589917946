
import { defineComponent, computed } from '@vue/composition-api'
import { provideEnv } from '@/composables/useEnvironment'

export default defineComponent({
  setup(props, context) {
    const route = computed(() => context.root.$route)

    provideEnv(route)
    return () => {
      const slotFn = context.slots.default
      if (slotFn) {
        return slotFn()
      }
      return null
    }
  }
})
