














































































































import Breadcrumb from './Breadcrumb.vue'
import NavigateBackButton from './NavigateBackButton.vue'
import TopMenu from './TopMenu.vue'
import EnvSwitcher from './EnvSwitcher.vue'
import LangSwitcher from './LangSwitcher.vue'
import useBreadcrumb from '@/composables/useBreadcrumb'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useAuth } from '@/lib/auth'
import { useEnv } from '@/composables/useEnvironment'

export default defineComponent({
  name: 'Layout',

  components: {
    Breadcrumb,
    TopMenu,
    EnvSwitcher,
    LangSwitcher,
    NavigateBackButton
  },

  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },

  setup(_, { root }) {
    const { showBreadcrumb } = useBreadcrumb()
    const { currentEnv, loading: envLoading, isEnvLoaded, isMaster } = useEnv()

    onMounted(() => {
      root.$message.config({
        getContainer: () =>
          document.querySelector('.main-layout-header') ||
          // eslint-disable-next-line
          document.querySelector('body')!
      })
    })

    const isSecondaryBarSticky = ref(false)

    const { logout, user, loading: authLoading } = useAuth()

    return {
      logout,
      user,
      authLoading,
      showSecondaryBar: showBreadcrumb,
      isSecondaryBarSticky,

      isMaster,
      currentEnv,
      envLoading,
      isEnvLoaded
    }
  }
})
