import VueBase, { VNode } from 'vue'
import App from './App.vue'
import VueCompositionApi from '@vue/composition-api'
import AntDesign from './plugins/antDesign'
import GlobalComponents from './plugins/globalComponents'
import VueRouter from 'vue-router'
import PortalVue from 'portal-vue'
import { routes } from './views/routes'
import { createVue } from '@/lib/utils'

VueBase.use(VueRouter)
VueBase.use(PortalVue)

const Vue = createVue(VueBase)
Vue.use(GlobalComponents)
Vue.use(VueCompositionApi)
Vue.use(AntDesign)

Vue.config.ignoredElements = ['eki-product-configurator']
Vue.prototype.$experimental = process.env.VUE_APP_EXPERIMENTAL

require('./styles/main.scss')
// load tailwind after AntDesign

Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,

  render(h): VNode {
    return h(App)
  }
}).$mount('#app')
