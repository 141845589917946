











import useBreadcrumb from '@/composables/useBreadcrumb'
import { defineComponent, computed } from '@vue/composition-api'
import { useEnv } from '../composables/useEnvironment'

export default defineComponent({
  name: 'Breadcrumb',

  setup(_, { root }) {
    const { withEnv } = useEnv()
    const { routes } = useBreadcrumb()
    const isShowed = computed(() => {
      return routes.value.length > 1
    })

    const navigateBack = () => {
      root.$router.push(withEnv(routes.value[routes.value.length - 2].path))
    }

    return {
      isShowed,
      navigateBack
    }
  }
})
